
.main-slide img {
    width:100px;
    margin-left:auto;
    margin-right:auto;
    display:block;
}
.main-slide h2 {
    margin-top:20px;
}
.main-slide h2, .main-slide h3, .main-slide h4 {
    margin-bottom:0px;
    text-align: center;
}
#slider {
    height:350px;
    min-height: 350px
}
#slider .swiper-slide {
        justify-content: center;
    display: flex;
    flex-direction: column;
}



